import React, { FC } from "react";
import styled from "styled-components";
import {
  compose,
  fontFamily,
  FontFamilyProps,
  fontWeight,
  FontWeightProps,
  letterSpacing,
  LetterSpacingProps,
  lineHeight,
  LineHeightProps,
  textAlign,
  TextAlignProps
} from "styled-system";
import { base, BaseProps, themed } from "./base";

const HeadingWrapper: FC<HeadingWrapperProps> = styled("p")<
  HeadingWrapperProps
>(
  compose(
    base,
    fontFamily,
    fontWeight,
    textAlign,
    lineHeight,
    letterSpacing,
    themed("Heading")
  )
);

export interface HeadingProps
  extends BaseProps,
    FontFamilyProps,
    FontWeightProps,
    TextAlignProps,
    LineHeightProps,
    LetterSpacingProps {}

interface HeadingWrapperProps extends HeadingProps {
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  content?: string;
}

const Heading: FC<HeadingWrapperProps> = ({ content, ...props }) => (
  <HeadingWrapper {...props}>{content}</HeadingWrapper>
);

export default Heading;

Heading.defaultProps = {
  as: "h2",
  mt: 0,
  mb: "1rem",
  fontWeight: "bold"
};
