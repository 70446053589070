import React, { Fragment } from "react";
import { serverUrl } from "../lib/config";
import { formatPrice } from "../lib/formatPrice";
import Box from "./elements/Box";
import Heading from "./elements/Heading";
import Image from "./elements/Image";
import Text from "./elements/Text";
import Pagination from "./Pagination";
import Paragraph from "./Paragraph";
import Section from "./Section";

const PropertyDetail = ({
  pid,
  title,
  imageUrls,
  size,
  area,
  category,
  info,
  price,
  characteristics,
  next,
  prev,
  location
}) => {
  return (
    <Section
      mt={["4rem", 0, "2rem"]}
      title={title}
      css={`
        a {
          text-decoration: none;
          font-size: 20px;
          color: ${props => props.theme.colors.primary};
        }
      `}
    >
      <Pagination
        showBackIndex
        detailPage
        location={location}
        hasBack={!!prev}
        hasNext={!!next}
        back={{ to: `/properties/p?pid=${prev}`, state: location.state }}
        backLabel="上一個"
        next={{ to: `/properties/p?pid=${next}`, state: location.state }}
        nextLabel="下一個"
      />

      <Box flexBox flexWrap="wrap">
        {imageUrls.map((t, i) => (
          <Image
            animate
            key={i}
            src={`${serverUrl}${t}`}
            alt={title}
            width={[1, 1 / 2, 1 / 3]}
            pr={[
              0,
              (i + 1) % 2 === 0 ? "0.25rem" : 0,
              (i + 1) % 3 === 0 ? "0.25rem" : 0
            ]}
            pl={[0, "0.25rem"]}
            py={["calc(0.25rem / 2)"]}
            css={`
              min-height: 268px;
              max-height: 268px;
            `}
          />
        ))}
      </Box>

      <Paragraph>
        {/* {description !== "" && (
            <Text
              fontSize={[2, 3]}
              my={4}
              lineHeight={2}
              content={description}
            ></Text>
          )} */}

        <Box flexBox flexWrap="wrap" width={1} alignItems="flex-start">
          <Box width={[1, 1 / 2]}>
            <Heading content="物業詳細信息" mt={[4, 6]} />
            <Box
              flexBox
              flexWrap="wrap"
              width={[1, 3 / 4]}
              justifyContent="space-between"
            >
              <Text width={1 / 2} content="物業編號"></Text>
              <Text width={1 / 2} content={pid}></Text>
              {info.map((i, index) => {
                // let title, value;
                // title = capitalize(key);
                // value = info[key];
                // if (key === "price") {
                //   value = formatPrice(info[key]);
                // }
                return (
                  <Fragment key={`info-${index}`}>
                    <Text width={1 / 2} content={i.key} />
                    <Text width={1 / 2} content={i.value} />
                    {index === 0 && (
                      <>
                        <Text width={1 / 2} content={"地區"} />
                        <Text width={1 / 2} content={area} />
                      </>
                    )}
                    {index === 1 && (
                      <>
                        <Text width={1 / 2} content={"類型"} />
                        <Text width={1 / 2} content={category} />
                      </>
                    )}
                  </Fragment>
                );
              })}
              <Text width={1 / 2} content="面積"></Text>
              <Text width={1 / 2} content={size}></Text>
              <Text width={1 / 2} content="房價"></Text>
              <Text width={1 / 2} content={formatPrice(price)}></Text>
            </Box>
          </Box>
          {characteristics && characteristics.length > 0 && (
            <Box width={[1, 1 / 2]}>
              <Heading content="物業特色" mt={[4, 6]} />
              <ul>
                {characteristics.map((c, i) => (
                  <li key={`characteriestic-${i}`}>{c}</li>
                ))}
              </ul>
            </Box>
          )}
        </Box>
      </Paragraph>

      <Pagination
        location={location}
        showBackIndex
        detailPage
        hasBack={!!prev}
        hasNext={!!next}
        back={{ to: `/properties/p?pid=${prev}`, state: location.state }}
        backLabel="上一個"
        next={{ to: `/properties/p?pid=${next}`, state: location.state }}
        nextLabel="下一個"
      />
    </Section>
  );
};

export default PropertyDetail;
