import { useQuery } from "@apollo/react-hooks";
import { navigate } from "gatsby";
import gql from "graphql-tag";
import queryString from "query-string";
import React from "react";
import Box from "../../components/elements/Box";
import Layout from "../../components/Layout";
import PlaceholderBox from "../../components/PlaceholderBox";
import PropertyDetail from "../../components/PropertyDetail";
import Section from "../../components/Section";
import SEO from "../../components/Seo";

const PropertyPage = ({ location }) => {
  const { pid } = queryString.parse(location.search);
  const {
    area = undefined,
    category = undefined,
    price = undefined
  }: {
    area: undefined | string;
    category: undefined | string;
    price: undefined | string;
  } = location.state || {};

  const { data, loading, error } = useQuery(
    gql`
      query Property(
        $pid: String!
        $area: String
        $price: String
        $category: String
      ) {
        property(pid: $pid, area: $area, category: $category, price: $price) {
          pid
          title
          size
          price
          area
          category
          imageUrls
          characteristics
          info {
            key
            value
          }
          prev
          next
        }
      }
    `,
    {
      variables: {
        pid,
        area: area ? area : undefined,
        category: category ? category : undefined,
        price: price ? price : undefined
      }
    }
  );

  if (error || (!loading && !data.property)) {
    navigate("/properties/");
    return null;
  }

  return (
    <Layout transparentHeader={false}>
      <SEO title="精選樓盤" />
      <Box height={["2rem", "4rem"]} />

      {loading ? (
        <Section mt={["3rem", 0]} px={["0.5rem"]}>
          <PlaceholderBox
            mt={["2.5rem"]}
            mb={3}
            mx="auto"
            px={[1]}
            width={[1, 1 / 2]}
          />
          <PlaceholderBox
            mt={[".5rem"]}
            mb={["4.4rem"]}
            mx="auto"
            height={["15px"]}
            width={["120px"]}
          />

          <Box flexBox flexWrap="wrap">
            {Array.from({ length: 12 }).map((_, i) => (
              <Box
                key={`placeholder-${i}`}
                width={[1, 1 / 2, 1 / 3]}
                pr={[
                  0,
                  (i + 1) % 2 === 0 ? "0.25rem" : 0,
                  (i + 1) % 3 === 0 ? "0.25rem" : 0
                ]}
                pl={[0, "0.25rem"]}
                py={["calc(0.25rem / 2)"]}
              >
                <PlaceholderBox height={260} />
              </Box>
            ))}
          </Box>
        </Section>
      ) : (
        <PropertyDetail {...{ ...data.property, location }} />
      )}
    </Layout>
  );
};

export default PropertyPage;
