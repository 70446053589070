import { LinkProps, Location } from "@reach/router";
import { Link } from "gatsby";
import React, { FC } from "react";
import Icon from "react-icons-kit";
import { chevronLeft } from "react-icons-kit/fa/chevronLeft";
import { chevronRight } from "react-icons-kit/fa/chevronRight";
import Box from "../components/elements/Box";

type Props = {
  hasBack: boolean;
  hasNext: boolean;
  back: LinkProps<{ page: number }>;
  next: LinkProps<{ page: number }>;
  backLabel?: string;
  nextLabel?: string;
  showPages?: boolean;
  totalCount?: number;
  currentPage?: number;
  showBackIndex?: boolean;
  location?: Location;
  detailPage?: boolean;
};

const Pagination: FC<Props> = ({
  hasBack,
  back,
  backLabel = "上一頁",
  hasNext,
  next,
  nextLabel = "下一頁",
  totalCount,
  currentPage,
  showPages = false,
  showBackIndex = false,
  location,
  detailPage = false
}) => (
  <Box flexBox width={1} mt={[4]} mb={[2, 4]} justifyContent="space-between">
    <Box
      width={[1 / 3]}
      flexBox
      justifyContent="flex-start"
      alignItems="center"
    >
      {hasBack && (
        <Link rel="prev" to={back.to} state={back.state}>
          <Icon icon={chevronLeft} size={22} />
          {backLabel}
        </Link>
      )}
    </Box>

    {showBackIndex && (
      <Box
        width={[1 / 3]}
        justifyContent="center"
        display={["flex", "flex", "none"]}
      >
        <Link to={`/properties/`} state={location && location.state} rel="Back">
          回目錄
        </Link>
      </Box>
    )}

    {showPages && (
      <Box
        minHeight="60px"
        width={[1 / 3]}
        mx="auto"
        flexBox
        justifyContent="center"
        alignItems="center"
      >
        {Array.from({ length: Math.ceil(totalCount / 30) }, (_, i) => {
          if (i + 1 < currentPage - 3 || i + 1 > currentPage + 3) return;

          if (currentPage === i + 1) {
            return (
              <span
                key={`pagination-number${i + 1}`}
                css={`
                  font-size: 2rem;
                  margin: 0 0.5rem;
                  padding-bottom: 1rem;
                  color: ${props => props.theme.colors.success};
                `}
              >
                {i + 1}
              </span>
            );
          }

          return (
            <Box
              css={`
                margin: 0 0.2rem;
              `}
              key={`pagination-number${i + 1}`}
              display={["none", "block"]}
            >
              <Link
                to="/properties/"
                state={{ ...location.state, page: i + 1 }}
              >
                {i + 1}
              </Link>
            </Box>
          );
        })}
      </Box>
    )}

    <Box flexBox width={[1 / 3]} justifyContent="flex-end" alignItems="center">
      {hasNext ? (
        <Link to={next.to} state={next.state} rel="next">
          {nextLabel}
          <Icon icon={chevronRight} size={22} />
        </Link>
      ) : (
        detailPage && (
          <Link
            to={`/properties/`}
            state={location && location.state}
            rel="Back"
          >
            回目錄
          </Link>
        )
      )}
    </Box>
  </Box>
);

export default Pagination;
