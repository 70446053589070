import React, { FC } from "react";
import Box, { BoxProps } from "./elements/Box";

const PlaceholderBox: FC<BoxProps> = ({
  height = 60,
  width = [1],
  backgroundColor = "#fafafa",
  ...rest
}) => (
  <Box
    height={height}
    width={width}
    bg={backgroundColor}
    css={`
      border-radius: 4px;
      overflow: hidden;
      animation: pulse 1.2s infinite ease-in-out;

      @keyframes progress {
        0% {
          transform: translate3d(-100%, 0, 0);
        }
        100% {
          transform: translate3d(100%, 0, 0);
        }
      }

      @keyframes pulse {
        0% {
          background-color: rgba(165, 165, 165, 0.1);
        }
        50% {
          background-color: rgba(165, 165, 165, 0.3);
        }
        100% {
          background-color: rgba(165, 165, 165, 0.1);
        }
      }

      // &:after {
      //   display: block;
      //   content: "";
      //   position: absolute;
      //   width: 100%;
      //   height: 100%;
      //   transform: translateX(-100%);
      //   background: linear-gradient(
      //     90deg,
      //     transparent,
      //     rgba(220, 220, 220, 0.8),
      //     transparent
      //   );
      //   animation: progress 1s ease-in-out infinite;
    `}
    {...rest}
  ></Box>
);

export default PlaceholderBox;
